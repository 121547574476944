<template>
  <div class="row">
    <div class="col" v-if="deviceDisplayers">
      <device-data
        :displayers="deviceDisplayers"
        :data-background-color="getTheme"
      />
    </div>
  </div>
</template>

<script>
import DeviceData from "../../components/Devices/DeviceData";
import { mapGetters } from "vuex";
export default {
  components: {
    DeviceData,
  },
  data: () => ({
    deviceDisplayers: null,
  }),
  computed: {
    ...mapGetters(["getTheme"]),
  },
  methods: {
    getDeviceDisplayers() {
      this.$store
        .dispatch("displayers/getDeviceDisplayers", this.$route.params.device)
        .then((data) => {
          this.deviceDisplayers = data;
        });
    },
  },
  mounted() {
    this.getDeviceDisplayers();
  },
};
</script>